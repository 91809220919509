<template>
  <div class="myticket" v-if="loadComponent">
    <v-container v-if="userRole.toUpperCase().includes('BLACKLIST')">
      <div>
        <v-row
          justify="center"
          class="null-item"
        >
          <v-col class="d-flex justify-center pb-0" cols="10">
            <v-img
              class="image"
              :src="require('../../assets/bus-homepage.svg')"
            ></v-img>
          </v-col>
          <v-col class="d-flex justify-center py-0 mt-4" cols="12">
            <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
          </v-col>
        
        </v-row>
      </div>
    </v-container>
    <v-container v-else>
      <v-row
        v-if="itemMD.length === 0"
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('@/assets/icon/no-ticket.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0" cols="12">
          <h2 class="notfication">{{ $t("NoTicket") }}</h2>
        </v-col>
        <v-col class="d-flex justify-center mt-3">
          <v-btn
            v-if="$partnerACL.isShowButton('purchase')"
            :color="$partnerACL.getButtonColor()"
            rounded
            width="80%"
            class="capitalize body-1"
            @click="clickPurchase()"
          >
            {{ $t("PurchaseTicket") }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row align="center" justify="center" no-gutters v-for="item in itemFF" :key="item.ticket_token">
        <ticketffcard
          :orig="item.orig_loc"
          :original="item.original_loc"
          :routename="item.cart_item_name"
          :expticket="item.ticket_expired_date"
          :dest="item.dest_loc"
          :destination="item.destination_loc"
          :itemFF="item"
          viewticket="Details"
        ></ticketffcard>
      </v-row> -->
      <v-row
        v-else
        align="center"
        justify="center"
        no-gutters
        v-for="item in itemMD"
        :key="item.ticket_token"
      >
        <ticketmdcard :itemFF="item" viewticket="Details"></ticketmdcard>
      </v-row>
      <!-- <v-row align="center" justify="center" no-gutters v-for="item in itemSD" :key="item.ticket_token">
        <ticketmdcard
          :itemFF="item"
          viewticket="Details"
        ></ticketmdcard>
      </v-row> -->
      <v-footer fixed app width="auto" color="white" elevation="3">
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" align-self="center">
              <v-btn
                rounded
                block
                :disabled="btnUseTicketDisable"
                class="text-capitalize"
                color="primary"
                @click="clickScanQR()"
              >
                {{ $t("Scan") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-container>
  </div>
</template>


<script>
import store from "@/store";
// import ticketffcard from "@/components/CardTicketFlate.vue";
import ticketmdcard from "@/components/CardTicketMultiDays.vue";

export default {
  name: "ViewTicket",
  components: { ticketmdcard },
  data() {
    return {
      item: this.$route.params.data,
      notifSuccess: false,
      notifFailed: false,
      itemFF: [],
      itemMD: [],
      itemSD: [],
      psg_id: store.state.auth.userData.psg_id,
      loadComponent:false
    };
  },
  computed:{
    btnUseTicketDisable(){
      let data = false
      if(this.itemFF.length == 0 && this.itemMD.length == 0 && this.itemMD.length == 0){
        data = true
      }
      return data
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  methods: {
    clickScanQR() {
      let routeData = this.$router.resolve({ name: "ScanQRBus" });
      window.location.href = routeData.href;
    },
    clickPurchase() {
      const purchaseButton = this.$partnerACL.getMetaButton('purchase');
      if(purchaseButton){
        if(purchaseButton.menu_type == "wsc_menu"){
            this.$router.push({
              name: purchaseButton.redirect_name,
            });
        }else {
          window.location = purchaseButton.redirect_path;
        }
      }else {
        this.$router.push({
          name: "Ticket",
        });
      }
    },
  },
  mounted() {
    const payload = {
      psg_id: this.psg_id,
    };
    // this.$store
    // .dispatch("myticket/flatefare", payload)
    // .then((response) => {
    //   this.itemFF = [];
    //   for (let data of response) {
    //     this.itemFF.push(data);
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    this.$store
      .dispatch("myticket/multidays", payload)
      .then((response) => {
        this.itemMD = [];
        this.loadComponent=true
        for (let data of response) {
          this.itemMD.push(data);
        }
      })
      .catch((err) => {
        this.loadComponent=true
        console.log(err);
      });
    // this.$store
    // .dispatch("myticket/studentfare", payload)
    // .then((response) => {
    //   this.itemSD = [];
    //   for (let data of response) {
    //     this.itemSD.push(data);
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    
    const payloadd = {
      cart_psg_id: this.psg_id,
    };
    if(!this.userRole.toUpperCase().includes('BLACKLIST')){
      this.$store
        .dispatch("purchaseticket/getQtyTicket", payloadd)
        .then((response) => {
          this.itemMT = [];
          for (let data of response) {
            this.itemMT.push(data);
            console.log("Ini Data Myticket :", data);
            // console.log("data asa",data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.$store.commit("purchaseticket/GETQTYTICKET_SUCCESS", 0);
    }
    
  },
};
</script>

<style lang="scss" scoped>
.head {
  overflow: hidden;
}
.null-item {
  position: absolute;
  top: 46%;
  right: 50%;
  transform: translate(46%, -50%);
}
</style>